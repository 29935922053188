import React, { Component } from 'react';
import { HashRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import Header from '../common/template/header';
import SideBar from '../common/template/sideBar';
import Footer from '../common/template/footer';
import Messages from '../common/msg/messages';
import imagemLogoBrancaHorizontal from '../assets/images/logo_letsara_branca_horizontal.png';

import Routes from './routes';

class App extends Component {

	state = {
		carregando: true,
		opacity: 0
	}

	constructor(props) {
		super(props);

		setTimeout(() => {
			this.setState({ ...this.state, carregando: false })
		}, 4000);
		setTimeout(() => {
			this.setState({ ...this.state, opacity: 1 })
		}, 1000);
		setTimeout(() => {
			this.setState({ ...this.state, opacity: 0 })
		}, 3000);
    }

	render() {
		return (
			<HashRouter>
				{this.state.carregando ?

					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: '#1f3269',
							height: '100vh',
							opacity: this.state.opacity,
							transition: 'opacity 1.5s ease-in-out'
						}}>
						<img src={imagemLogoBrancaHorizontal} style={{ maxHeight: 70, height: 'auto', maxWidth: 300, width: window.innerWidth - 40, marginBottom: 32 }} />

						<div style={{ color: '#ffffff', fontSize: 18, marginBottom: 8 }}>SIGA NOSSAS REDES SOCIAIS!</div>

						<div style={{ color: '#ffffff', fontSize: 20, fontWeight: 'bold', marginBottom: 16 }}>@letsaralogistica</div>

						<div>
							<i style={{ color: '#ffffff', fontSize: 42 }} class='fab fa-facebook-square'></i>

							<i style={{ color: '#ffffff', fontSize: 42, marginLeft: 16, marginRight: 16 }}  class='fab fa-instagram-square'></i>

							<i style={{ color: '#ffffff', fontSize: 42 }}  class='fab fa-linkedin'></i>
						</div>
					</div>

				:
					<div className='wrapper'>
						<Header />
						<SideBar />
						<Routes usuarioCarregado={this.props.usuarioCarregado} />
						<Messages />
					</div>
				}
			</HashRouter>
		);
	}
}


const mapStateToProps = state => ({
	sessao: state.auth.usuario
});
export default connect(mapStateToProps, null)(App);
